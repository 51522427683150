import { Button, Card, Col, Input, Row, Space } from "antd";
import React from "react";
import { Sections } from "../../defaults";
import { Description, Form, FormItem, SecondTitle } from "../../styles/styled";
import TextArea from "antd/es/input/TextArea";

function Contact() {
  return (
    <>
      <Row justify="center" id={Sections.CONTACT}>
        <SecondTitle>Kontakt</SecondTitle>
      </Row>

      <Row justify="center">
        <Col span={20} sm={{ span: 16 }}>
          <Description style={{ fontSize: "1rem", lineHeight: "1.2rem" }}>
            Imate pitanja, prijedloge ili želite saznati više o našim uslugama? Javite nam se! Naš
            tim je ovdje da vam pruži podršku i odgovori na sva vaša pitanja.
          </Description>
        </Col>
      </Row>

      <Row justify="center">
        <Col style={{ width: "200px" }}>
          Email: <a href="mailto:info@zp-mont.hr">info@zp-mont.hr</a>
        </Col>
        <Col>
          Telefon: <a href="tel:+385957793548">+385 95 779 3548</a>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "56px" }}>
        <Col span={4} xs={{ span: 20 }}>
          <Form action="https://formsubmit.co/info@zp-mont.hr" method="POST" name="contact-form">
            <FormItem>
              <label htmlFor="name">
                Ime i prezime<span>*</span>
              </label>
              <Input type="text" name="name" required />
            </FormItem>
            <FormItem>
              <label htmlFor="email">
                Email<span>*</span>
              </label>
              <Input type="email" name="email" required />
            </FormItem>
            <FormItem>
              <label htmlFor="subject">Naslov</label>
              <Input type="text" name="subject" />
            </FormItem>
            <FormItem>
              <label htmlFor="message">Poruka</label>
              <TextArea name="message" />
            </FormItem>
            <Button type="primary" htmlType="submit" block>
              Pošalji
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Contact;
