import { Col, Row } from "antd";
import styled from "styled-components";

export const MainTitle = styled.h1`
  margin: 0 auto;
  font-size: 5rem;
  color: #363435;
  position: relative;
  z-index: 2;
`;

export const SecondTitle = styled.h2`
  margin: 0 auto;
  font-size: 3rem;
  color: #363435;
`;

export const ThirdTitle = styled.h3`
  margin: 0 auto;
  font-size: 1rem;
  color: #363435;
`;

export const CenteredCol = styled(Col)`
  display: grid;
  place-content: center;
`;

export const RowWithBg = styled(Row)`
  background-image: ${({ bgUrl }) => `url("${bgUrl}");`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 7rem 0;
  z-index: 0;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0 0 0 / 5%);
    z-index: 1;
  }
`;

export const CenteredColWithBg = styled(CenteredCol)``;

export const Description = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: #363435;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 1rem;
    line-height: 1.6rem;
  }
`;

export const AnchorColumn = styled.div`
  display: grid;
  place-content: center;
  row-gap: 12px;
`;

export const Anchor = styled.span`
  all: unset;
  cursor: pointer;
  color: #363435;

  &:hover {
    text-decoration: underline;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  margin: 0 auto;
  max-width: 600px;

  button {
    margin-top: 12px;
    width: fit-content !important;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  & > label > span {
    color: red;
  }
`;
