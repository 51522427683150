import React from "react";
import { Layout } from "antd";
import Header from "./components/layout/Header";
import Content from "./components/content/Content";
import Footer from "./components/layout/Footer";

function App() {
  return (
    <Layout>
      <Header />
      <Content />
      <Footer />
    </Layout>
  );
}

export default App;
