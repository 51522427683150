import { Col, Row } from "antd";
import React from "react";
import { Sections } from "../../defaults";
import { Description, SecondTitle } from "../../styles/styled";

function About() {
  return (
    <>
      <Row justify="center" id={Sections.ABOUT}>
        <SecondTitle>O Nama</SecondTitle>
      </Row>
      <Row align="middle" justify="center" style={{ padding: "0px 24px" }}>
        <Col lg={{ span: 16 }}>
          <Description>
            Naša tvrtka specijalizirana je za montažu, demontažu i servisiranje aluminijumskih i PVC
            stolarija. Sa stručnim timom visoko kvalificiranih radnika, nudimo sveobuhvatne usluge
            koje zadovoljavaju potrebe naših klijenata. Bez obzira radi li se o montaži novih
            prozora i vrata, demontaži postojeće stolarije ili servisiranju kako bi se osigurala
            dugotrajnost i funkcionalnost, pružamo pouzdano i efikasno rješenje. Kombinirajući
            vrhunsku tehničku ekspertizu s pažljivim pristupom, garantiramo kvalitetnu uslugu i
            zadovoljstvo naših klijenata.
          </Description>
        </Col>
      </Row>
    </>
  );
}

export default About;
