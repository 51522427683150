import React from "react";
import { Content as ContentComponent } from "antd/es/layout/layout";
import Main from "./sections/Main";
import { Divider } from "antd";
import About from "./sections/About";
import Services from "./sections/Services";
import Contact from "./sections/Contact";

function Content() {
  return (
    <ContentComponent style={{ marginTop: "24px" }}>
      <Main />

      <Divider />

      <About />

      <Divider />

      <Services />

      <Divider />

      <Contact />
    </ContentComponent>
  );
}

export default Content;
