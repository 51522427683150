import React, { useCallback } from "react";
import { Footer as FooterComponent } from "antd/es/layout/layout";
import { Anchor, AnchorColumn, ThirdTitle } from "../styles/styled";
import { Col, Image, Row } from "antd";
import { Sections } from "../defaults";

function Footer() {
  const onAnchorClick = useCallback((elementId) => {
    const elementOnPage = document.getElementById(elementId);
    if (elementOnPage) {
      elementOnPage.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <FooterComponent style={{ marginTop: "56px" }}>
      <Row align="middle">
        <Col span={12}>
          <Row justify="center">
            <Image
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              src="/img/z&p-logo.png"
              style={{ width: "70px" }}
              preview={false}
            />
          </Row>
          <Row justify="center" style={{ marginTop: "12px" }}>
            <ThirdTitle>Z&P Mont</ThirdTitle>
          </Row>
        </Col>

        <Col span={12}>
          <AnchorColumn>
            <Anchor onClick={() => onAnchorClick(Sections.ABOUT)}>O Nama</Anchor>
            <Anchor onClick={() => onAnchorClick(Sections.SERVICES)}>Usluge</Anchor>
            <Anchor onClick={() => onAnchorClick(Sections.CONTACT)}>Kontakt</Anchor>
          </AnchorColumn>
        </Col>
      </Row>
    </FooterComponent>
  );
}

export default Footer;
