import { Menu } from "antd";
import React, { useCallback } from "react";
import { Sections } from "../defaults";
import {
  InfoCircleOutlined,
  MenuOutlined,
  MessageOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

const DesktopItems = [
  {
    key: Sections.ABOUT,
    label: "O Nama",
    icon: <InfoCircleOutlined />,
  },
  {
    key: Sections.SERVICES,
    label: "Usluge",
    icon: <SnippetsOutlined />,
  },
  {
    key: Sections.CONTACT,
    label: "Kontakt",
    icon: <MessageOutlined />,
  },
];

const MobileItems = [
  {
    key: "hamburger",
    icon: <MenuOutlined />,
    children: DesktopItems,
  },
];

function Navigation() {
  const onNavSelection = useCallback((elementId) => {
    const elementOnPage = document.getElementById(elementId);
    if (elementOnPage) {
      elementOnPage.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Menu
        id="navbar-desktop"
        style={{ justifyContent: "flex-end" }}
        items={DesktopItems}
        mode="horizontal"
        theme="light"
        onClick={(item) => onNavSelection(item.key)}
      />

      <Menu
        id="navbar-mobile"
        style={{ justifyContent: "flex-end" }}
        items={MobileItems}
        mode="horizontal"
        theme="light"
        onClick={(item) => onNavSelection(item.key)}
      />
    </>
  );
}

export default Navigation;
