import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import App from "./App";

import "./components/styles/simple.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#363435",
        },
        components: {
          Layout: {
            headerBg: "#fff",
            bodyBg: "#fff",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);
