import { Col, List, Row, Segmented, Space } from "antd";
import React, { useState } from "react";
import { CenteredCol, SecondTitle } from "../../styles/styled";
import { Sections, ServicesData, Services as ServicesList } from "../../defaults";

function Services() {
  const [selectedService, setSelectedService] = useState(ServicesList.MONTAZA);

  return (
    <>
      <Row justify="center" id={Sections.SERVICES}>
        <SecondTitle>Usluge</SecondTitle>
      </Row>
      <Row align="middle" justify="center" style={{ padding: "0px 12px" }}>
        <Col md={{ span: 12 }} span={24} style={{ marginTop: "24px" }}>
          <Segmented
            options={Object.values(ServicesList)}
            onChange={(service) => setSelectedService(service)}
            size="large"
            block={true}
          />
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "12px" }}>
        <Col span={20}>
          <List
            itemLayout="horizontal"
            dataSource={ServicesData[selectedService]}
            renderItem={({ title, description }, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={title} description={description} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}

export default Services;
