export const Sections = {
  ABOUT: "about",
  SERVICES: "services",
  CONTACT: "contact",
};

export const Services = {
  MONTAZA: "Montaža",
  DEMONTAZA: "Demontaža",
  SERVISIRANJE: "Servisiranje",
};

export const ServicesData = {
  [Services.MONTAZA]: [
    {
      title: "Priprema prostora",
      description:
        "Prije početka montaže, prostor u kojem će se postavljati stolarija treba biti pripremljen. To može uključivati čišćenje područja, uklanjanje prepreka ili starih elemenata stolarije, te provjeru ravnosti i stabilnosti okvira.",
    },
    {
      title: "Izbor materijala",
      description:
        "Potrebno je osigurati sve potrebne materijale i alate za postavljanje stolarije. To uključuje samu stolariju (prozore, vrata), brtvene materijale (npr. silikon), elemente za pričvršćivanje (vijke, klinove) te alate poput libele, odvijača, brusilice i sl.",
    },
    {
      title: "Mjerenje",
      description:
        "Precizno mjerenje prostora ključno je kako bi nova stolarija odgovarala dimenzijama i bila pravilno postavljena. Mjerenja trebaju uzeti u obzir visinu, širinu, dubinu i nagib, ovisno o vrsti stolarije koja se postavlja.",
    },
    {
      title: "Priprema stolarije",
      description:
        "Stolarija se može pripremiti ovisno o vrsti materijala. To može uključivati dodavanje brtvi, nanošenje premaza ili boje te eventualno izradu otvora za ručke ili brave.",
    },
    {
      title: "Postavljanje",
      description:
        "Nakon što je prostor pripremljen i stolarija spremna, slijedi postavljanje. Prozori ili vrata postavljaju se u otvor koristeći odgovarajuće nosače ili postolja kako bi se osigurala stabilnost. Zatim se provjerava i podešava pravilno poravnanje i otvaranje/zatvaranje.",
    },
    {
      title: "Fiksiranje i brtvljenje",
      description:
        "Nakon što je stolarija postavljena, slijedi fiksiranje pomoću vijaka, klinova ili drugih prikladnih elemenata za pričvršćivanje. Nakon toga, prostor između stolarije i zida obično se brtvi kako bi se spriječili propusti zraka ili vode. To se obično radi s brtvilom ili silikonskim kitom.",
    },
    {
      title: "Podešavanje i provjera",
      description:
        "Nakon montaže, važno je provjeriti funkcionalnost stolarije. To uključuje provjeru otvaranja/zatvaranja, brtvljenja, kao i ispitivanje na propuste zraka ili vode. Ako je potrebno, vrši se fino podešavanje kako bi se osiguralo da stolarija radi glatko i bez problema.",
    },
    {
      title: "Završna obrada",
      description:
        "Nakon montaže, može biti potrebno obaviti završne radove kao što su bojanje, lakiranje ili nanošenje dekorativnih elemenata kako bi se stolarija estetski uklopila u prostor.",
    },
  ],
  [Services.DEMONTAZA]: [
    {
      title: "Priprema prostora",
      description:
        "Prije početka demontaže, potrebno je pripremiti prostor oko stolarije. To može uključivati uklanjanje namještaja ili drugih prepreka kako bi se osigurao lakši pristup stolariji. Također je važno osigurati radni prostor tako da se sprečava oštećenje podova ili drugih površina tijekom demontaže.",
    },
    {
      title: "Procjena stanja stolarije",
      description:
        "Korisno je procijeniti stanje stolarije i okolnih elemenata. To može uključivati provjeru integriteta okvira, šarki, brtvi i drugih dijelova stolarije kako bi se utvrdilo treba li popravak ili zamjena nekih dijelova prije demontaže.",
    },
    {
      title: "Uklanjanje okova",
      description:
        "Prvi korak u demontaži stolarije obično je uklanjanje svih okova, poput ručki, brave, šarki ili drugih elemenata koji drže stolariju na mjestu. Ovo se obično radi koristeći odvijače ili druge odgovarajuće alate.",
    },
    {
      title: "Otpuštanje brtvi",
      description:
        "Ako je stolarija bila brtvljena s okolnim zidom ili okvirom, potrebno je pažljivo otpustiti brtve kako bi se olakšalo uklanjanje stolarije. To se obično radi pomoću noža ili drugog alata za rezanje kako bi se oslobodio spoj između stolarije i zida.",
    },
    {
      title: "Uklanjanje fiksacija",
      description:
        "Nakon što su okovi i brtve uklonjeni, slijedi uklanjanje svih fiksacija koje drže stolariju na mjestu. To mogu biti vijci, klinovi ili drugi elementi koji su pričvršćeni na zid ili okvir.",
    },
    {
      title: "Pažljivo uklanjanje stolarije",
      description:
        "Kada su sve fiksacije uklonjene, stolarija se pažljivo uklanja iz otvora. Ovo obično zahtijeva više osoba kako bi se osigurala sigurnost i sprječilo oštećenje stolarije ili okolnih površina.",
    },
    {
      title: "Provjera stanja otvora",
      description:
        "Nakon uklanjanja stolarije, korisno je provjeriti stanje otvora kako bi se osiguralo da nema oštećenja ili problema koji bi trebali biti riješeni prije postavljanja nove stolarije.",
    },
    {
      title: "Odlaganje stolarije",
      description:
        "Nakon demontaže, stolarija se može odlagati ili reciklirati prema lokalnim propisima i smjernicama za odlaganje otpada.",
    },
  ],
  [Services.SERVISIRANJE]: [
    {
      title: "Vizualna inspekcija",
      description:
        "Početni korak u servisiranju stolarije obično uključuje temeljitu vizualnu inspekciju. Pregledava se stanje okvira, stakla, brtvi, okova, te se traže znakovi oštećenja ili propusta koji bi mogli utjecati na funkcionalnost ili energetsku učinkovitost.",
    },
    {
      title: "Mazanje okova",
      description:
        "Okovi poput šarki, brave i ručki mogu se s vremenom istrošiti ili zapeti zbog nakupljenog prljavštine ili korozije. U servisiranju se obično uključuje podmazivanje okova kako bi se osiguralo glatko otvaranje i zatvaranje stolarije. Koristi se odgovarajuće mazivo koje nije štetno za materijal okova.",
    },
    {
      title: "Zamjena brtvi",
      description:
        "Brtve su važne za sprječavanje propuštanja zraka i vode kroz spojeve stolarije. U servisiranju se provjerava stanje brtvi i po potrebi se zamjenjuju kako bi se osigurala njihova funkcionalnost.",
    },
    {
      title: "Čišćenje stakla",
      description:
        "Stakla na prozorima ili vratima treba redovito čistiti kako bi se održao njihov izgled i transparentnost. U servisiranju se stakla čiste od prljavštine, prašine ili tragova vlage.",
    },
    {
      title: "Provjeravanje funkcionalnosti",
      description:
        "Servisiranje uključuje provjeru funkcionalnosti stolarije, uključujući otvaranje, zatvaranje, zaključavanje i brtvljenje. Ako postoji bilo kakav problem ili nedostatak funkcionalnosti, serviser može prilagoditi ili popraviti stolariju prema potrebi.",
    },
    {
      title: "Popravci ili zamjene dijelova",
      description:
        "Ako se tijekom servisiranja otkriju oštećeni ili istrošeni dijelovi stolarije, poput okova, brtvi ili šarki, oni se mogu popraviti ili zamijeniti. To može uključivati ​​zamjenu stakla, podešavanje okova ili zamjenu cijelog okvira ako je potrebno.",
    },
    {
      title: "Prilagodba i podešavanje",
      description:
        "Ponekad je potrebno prilagoditi ili podešavati stolariju kako bi se osiguralo pravilno funkcioniranje. To može uključivati ​​prilagodbu nagiba prozora ili vrata kako bi se osiguralo bolje zatvaranje ili podešavanje brtvi za bolju izolaciju.",
    },
    {
      title: "Završni pregled",
      description:
        "Nakon što su sve potrebne radnje izvršene, obično se provodi završni pregled kako bi se osiguralo da je stolarija pravilno servisirana i da ispunjava sve zahtjeve.",
    },
  ],
};
