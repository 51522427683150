import React from "react";
import { CenteredColWithBg, MainTitle, RowWithBg } from "../../styles/styled";

function Main() {
  return (
    <RowWithBg id="home" align="middle" justify="center" bgUrl="/img/background.avif">
      <CenteredColWithBg span={24}>
        <MainTitle>Z&P Mont</MainTitle>
      </CenteredColWithBg>
    </RowWithBg>
  );
}

export default Main;
