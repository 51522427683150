import React from "react";
import { Header as HeaderComponent } from "antd/es/layout/layout";
import { Image } from "antd";
import Navigation from "../content/Navigation";

function Header() {
  return (
    <HeaderComponent
      style={{ display: "grid", gridTemplateColumns: "41px auto", columnGap: "16px" }}
    >
      <Image
        preview={false}
        src="/img/z&p-logo.png"
        style={{ height: "64px", width: "auto", cursor: "pointer" }}
        alt="logo"
        onClick={() => {
          const homeElement = document.getElementById("home");
          if (homeElement) {
            homeElement.scrollIntoView({ behavior: "smooth" });
          }
        }}
      />
      <Navigation />
    </HeaderComponent>
  );
}

export default Header;
